.header-cart{
  float: right;
  margin-top: 13px;
  & .drop-trigger{
    position: absolute;
    top: 0; right: 0; bottom: 0; left: 0;
    z-index: 1;
    cursor: pointer;
  }

  @media (max-width: 991px) {
    margin-top: 20px;
    margin-bottom: 20px;

    @media (max-width: 480px) {
      margin-top: 10px;
      margin-bottom: 10px;
    }
  }

}

.header-cart_preview{
  color: $white;
  font-size: 16px;
  font-weight: 300;
  line-height: 20px;
  border-radius: 25px;
  display: inline-block;
  float: right;
  background-color: $red;
  padding: 15px;
  & > div{
    display: inline-block;
  }

  & .fa{
    font-size: 22px;
    font-weight: 400;
  }

  @media (max-width: 480px) {
    padding: 10px;
  }
}






//header cart
.header-cart{
  & .drop-content-holder{
    width: 300px;
    border: 1px solid rgb(247,166,168);
    background-color: $red;
    /* Firefox v3.5+ */
    -moz-box-shadow:-1px -1px 10px rgba(0,0,0,0.3);
    /* Safari v3.0+ and by Chrome v0.2+ */
    -webkit-box-shadow:-1px -1px 10px rgba(0,0,0,0.3);
    /* Firefox v4.0+ , Safari v5.1+ , Chrome v10.0+, IE v10+ and by Opera v10.5+ */
    box-shadow:-1px -1px 10px rgba(0,0,0,0.3);
    -ms-filter:"progid:DXImageTransform.Microsoft.dropshadow(OffX=-1,OffY=-1,Color=#4d000000,Positive=true)";
    filter:progid:DXImageTransform.Microsoft.dropshadow(OffX=-1,OffY=-1,Color=#4d000000,Positive=true);
    color: $white;
    a {
      color: $white;
    }
  }
  & .drop-content{
    padding: 0 10px 10px 10px;
  }
  & .shop-cart-content{
    margin-top: 10px;
  }
  & .header-cart_product_list>li:first-of-type .header-cart_product_list_item{
    margin-top: 0;
  }
  & .header-cart_product_list>li:last-of-type .header-cart_product_list_item{
    &:before {
      display: none;
    }
  }
  & .header-cart_product_list_item{
    position: relative;
    padding-left: 110px;
    margin-top: 10px;
    padding-right: 20px;
    & .item-preview{
      position: absolute;
      left: 0; top: 0;
      display: inline-block;
      width: 80px;
      height: auto;
      border: 1px solid $white;
      background-color: $white;
      box-sizing: content-box;
    }
    & img{
      max-width: 100%;
      max-height: 100%;
    }
    & .right-part{
      position: relative;
      padding-bottom: 60px;
      min-height: 120px;
      & a:hover{
        color: $green;
      }
    }
    & h4{
      font-size: 14px;
    }
    & .item-cat{
      position: absolute;
      left: 0px; bottom: 10px;
      display: block;
      font-weight: 600;
      font-size: 14px;
      color: $white;
    }
    & .item-price{
      position: absolute;
      left: 0px; bottom: 10px;
      display: block; width: 100%;
      text-align: right;
      font-size: 16px;
      font-weight: 400;
      color: $white;
      & .price_decimal{
        font-size: 0.5em;
        position: relative;
        top: -0.6em;
      }
      & .price_currency{
        text-transform: uppercase;
      }
    }
    & .item-del{
      position: absolute;
      right: 0;
      bottom: 35px;
      &:hover {
        color: $green;
      }
    }
    &:before{
      content: '';
      position: absolute;
      bottom: 0; right: 20px; top: 100%; left: 0;
      height: 1px;
      background-color: #DDD;
    }
  }
  & .cart-total{
    line-height: 50px;
    text-align: center;
    color: $red;
    background-color: $white;
    font-size: 16px;
    margin-left: -10px;
    margin-right: -10px;
    margin-bottom: 10px;
    text-transform: uppercase;
    & .price_decimal{
      font-size: 0.5em;
      position: relative;
      top: -0.6em;
    }
  }
  & .cart-order a{
    margin-top: 1px;
    display: block;
    line-height: 38px;
    text-align: center;
    color: $white;
    background-color: transparent;
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 700;
    border-radius: 6px;
    border: 1px solid $white;
    &.button-change{
      margin-right: -8px;
    }
    &.button-order{
      margin-left: -8px;
      background-color: $red;
    }
    &:hover {
      background-color: #BC1216;
    }
  }
  .no_item_text{
    font-size: 18px;
    margin-top: 20px;
    color: $white;
    text-align: center;
    padding: 10px 0;
    text-transform: uppercase;
  }
}
@media(min-width: 768px){
  #cart.fixed{
    position: fixed!important;
    margin-top: 30px;
    top: 0; left: 50%;
    margin-left: 280px;

    & .header-cart_preview{
      min-width: 1px;

      .right {
        display: none;
      }
    }
    @media(max-width: 991px){
      & .drop-content-holder{
        max-width: 1000px!important;
      }
    }
  }
}

@media(max-width: 991px){
  .only-large{
    display: none;
  }
}

@media(min-width: 992px) and (max-width: 1199px){
  #cart.fixed{
    margin-left: 390px;
  }
}

@media(min-width: 1200px){
  #cart.fixed{
    margin-left: 490px;
  }
}


.fixed-header {


}

#notification_area {
  color: $red;
  margin-top: 20px;
}