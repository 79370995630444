#header {
  position: relative;
  z-index: 5;
}

.header-top {
  height: 55px;
  background-color: $white;
  /* Firefox v3.5+ */
  -moz-box-shadow:3px 3px 4px rgba(0,0,0,0.2);
  /* Safari v3.0+ and by Chrome v0.2+ */
  -webkit-box-shadow:3px 3px 4px rgba(0,0,0,0.2);
  /* Firefox v4.0+ , Safari v5.1+ , Chrome v10.0+, IE v10+ and by Opera v10.5+ */
  box-shadow:3px 3px 4px rgba(0,0,0,0.2);
  -ms-filter:"progid:DXImageTransform.Microsoft.dropshadow(OffX=3,OffY=3,Color=#33000000,Positive=true)";
  filter:progid:DXImageTransform.Microsoft.dropshadow(OffX=3,OffY=3,Color=#33000000,Positive=true);
  position: relative;
  z-index: 1;


  .logo-small {
    display: none;
  }

  .contact-data {
    line-height: 25px;
    padding: 15px 0 0 0;
    display: inline-block;
    > span {
      display: inline-block;
      padding-left: 10px;
      &:first-of-type {
        padding-right: 20px;
      }
    }
    i {
      display: inline-block;
      padding-right: 5px;
    }
  }

  @media (max-width: 991px) {
    height: 55px;
    padding: 0;
    .contact-data {
      padding: 10px 0;
      display: inline-block;
      vertical-align: top;
      width: 1px;
      line-height: 15px;
      > span {
        font-size: 10px;
        display: inline-block;
        padding-left: 10px;
        text-wrap: none;
        white-space: nowrap;
        &:first-of-type {
          padding-right: 20px;
        }
      }
      i {
        display: inline-block;
        padding-right: 5px;
      }
    }

    .logo-small {
      display: inline-block;
      padding-top: 13px;
      vertical-align: top;
      img {
        height: 30px; width: auto;
      }
    }
  }
}

.header-bottom {
  background-color: #fcfcfc;
  @media (min-width: 992px) {
    padding: 30px 0 50px 0;
  }
}

#logo {
  display: inline-block;
  float: left;
  @media (max-width: 991px) {
    display: none;
  }
}


.responsive-menu {
  .top {
    height: 55px;
    background-color: $white;
    /* Firefox v3.5+ */
    -moz-box-shadow:3px 3px 4px rgba(0,0,0,0.2);
    /* Safari v3.0+ and by Chrome v0.2+ */
    -webkit-box-shadow:3px 3px 4px rgba(0,0,0,0.2);
    /* Firefox v4.0+ , Safari v5.1+ , Chrome v10.0+, IE v10+ and by Opera v10.5+ */
    box-shadow:3px 3px 4px rgba(0,0,0,0.2);
    -ms-filter:"progid:DXImageTransform.Microsoft.dropshadow(OffX=3,OffY=3,Color=#33000000,Positive=true)";
    filter:progid:DXImageTransform.Microsoft.dropshadow(OffX=3,OffY=3,Color=#33000000,Positive=true);
    position: relative;
    z-index: 1;
  }

  .responsive-menu-close {
    position: absolute;
    right: 0; top: 0;
    display: inline-block;
    width: 30px; height: 30px;
    text-align: center; line-height: 30px;
    cursor: pointer;
  }

  .categories-block {
    margin-top: 30px;
    margin-bottom: 10px;

    @media(max-width: 767px) {
      margin-top: 20px;
    }

    @media(max-width: 480px) {
      margin-top: 10px;

      .categories-block-title{
        line-height: 20px;
        font-size: 16px;
        height: 40px; padding: 10px 10px;
        background: $blue;

        span {
          line-height: 20px;
          font-size: 16px;
        }
      }
    }

    #cat-acc {
      margin-bottom: 0;
    }
  }
}

.main-nav {

  @media (max-width: 991px) {
    &.nav-large {
      display: none;
    }

    line-height: 20px;
    margin-top: 20px;

    @media(max-width: 480px) {
      margin-top: 30px;
    }

    ul {
      display: table;
      table-layout: fixed;
      width: 100%;

      > li {
        display: table-cell;
        text-align: center;
        vertical-align: middle;
        border-left: 1px solid #ccc;
        @media (max-width: 480px) {
          font-size: 12px;
        }

        &:first-of-type {
          border: none;
        }
      }

      @media(max-width: 480px) {
        table-layout: auto;
      }
    }
  }

  @media (min-width: 992px) {
    margin-top: 13px;
    margin-right: 100px;
    float: right;
    display: inline-block;
    li {
      display: inline-block;
      float: left;
      padding: 15px 0;
      & + li a {
        border-left: 1px solid #ccc;
      }
      a {
        display: block;
        line-height: 20px;
        padding: 0 20px;
      }
    }
  }
}


.drop-holder{
  display: inline-block;
  position: relative;
  & .drop-trigger{

  }
  & .drop-content-holder{
    display: none;
    border: 1px solid $blue;
    position: absolute;
    top: 100%; right: 0;
    width: 400px;
    z-index: 1;
    background-color: $white;
    &.open{
      z-index: 2;
    }
  }
}



.my-account{
  float: right;

  .drop-trigger {
    cursor: pointer;
    padding: 15px 0;
    line-height: 25px;
    display: inline-block;
    &:hover,
    &.open{
      color: $blue
    }
    @media (max-width: 991px) {
      display: inline-block;
      padding: 15px 10px;
      line-height: 25px;
    }
  }

  & .drop-content-holder{
    min-width: 360px;
    padding: 20px;
    top: 55px;
    text-align: left;
    border: 3px solid $blue;
    box-shadow: -1px -1px 10px rgba(0,0,0,0.3);
    -ms-filter: "progid:DXImageTransform.Microsoft.dropshadow(OffX=-1,OffY=-1,Color=#4d000000,Positive=true)";
    filter: progid:DXImageTransform.Microsoft.dropshadow(OffX=-1,OffY=-1,Color=#4d000000,Positive=true);

    &.logged{
      min-width: 1px;
      padding: 10px;
      top: 45px;
      & li{
        & a{
          padding-left: 18px;
          padding-top: 5px;
          font-size: 15px;
          text-transform: none;
          color: $blue;
          position: relative;
          display: block;
          &:before{
            content: "\f105";
            position: absolute;
            left: 3px; top: 6px;
            font-family: FontAwesome;
            @include transition-all(0.4s);
          }
        }
        &:hover a{
          color:$blue;
          &:before{
            left: 7px;
          }
        }
      }
    }
  }
  @media(max-width: 480px){
    position: static;
    & .drop-content-holder{
      min-width: 1px;
      width: 100%;
    }
  }
  & .heading{
    position: relative;
    padding: 0 0 10px 0;
    color: $blue;
    &:before{
      position: absolute; left: 0; bottom: 0;
      content: "";
      width: 70px; height: 2px;
      background-color: $blue;
    }
  }
}

#login_form .input-holder{
  border: 1px solid #ddd;
  padding: 2px 2px 2px 31px;
  position: relative;
  margin-top: 20px;
  & input{
    margin-top: 0;
    border: 0;
    height: 30px;
    color: $black;
  }
  &  span{
    background-color: $blue;
    display: inline-block;
    width: 29px;
    height: 29px;
    color: #fff;
    position: absolute;
    left: 2px;
    top: 2px;
    text-align: center;
    line-height: 29px;
    z-index: 1;
    &:after{
      content: "";
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 5px 0 5px 6px;
      border-color: transparent transparent transparent $blue;
      position: absolute;
      top: 50%;
      margin-top: -5px;
      left: 100%;
    }
  }

}
#login_form .input-holder input::-webkit-input-placeholder {color: $blue; text-transform: uppercase;}
#login_form .input-holder input:-moz-placeholder {color: $blue; text-transform: uppercase;} /* Firefox 18- */
#login_form .input-holder input::-moz-placeholder {color: $blue; text-transform: uppercase;} /* Firefox 19+ */
#login_form .input-holder input:-ms-input-placeholder {color: $blue; text-transform: uppercase;}

#header #login_form{position: relative}
#header .forgotten_password{line-height: 20px; display: block; margin-top: 20px; padding-left: 5px; color: $blue; text-decoration: underline;}
#header .register_link{line-height: 20px; display: block; padding-left: 5px;  text-decoration: underline;}
#user_login_form_error {color: $red;}
#reset_notifications p {
  color: $red;
  margin-bottom: 0;
  position: relative;
  top: 10px;
}

.responsive-menu {
  position: fixed;
  height: 100%; width: 100%;
  top: 0; left: -110%;
  background-color: #f5f5f5;
  z-index: 99999;
  overflow-y: auto;
  @include transition-all(0.4s);
}


@media(max-width: 991px) {
  .responsive-menu-opened {
    .responsive-menu {
      left: 0;
    }
  }

  .responsive-menu-trigger {
    cursor: pointer;
    display: inline-block;
    line-height: 53px;
    margin-top: 20px;
    width: 50px;
    height: 53px;
    font-size: 26px;

    @media (max-width: 480px) {
      line-height: 43px;
      width: 40px;
      height: 43px;
      margin-top: 10px;
    }
  }
}

@media(min-width: 992px) {
  .responsive-menu-trigger {
    display: none;
  }
}
