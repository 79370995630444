.user-wishlist{
  h1 {
    font-size: 21px;
    font-weight: 700;
    margin: 30px 0 50px 0;
    text-transform: uppercase;
  }
}
.wishes-title{
  text-transform: uppercase;
  padding-top: 40px;
  font-size: 21px;
  font-weight: 700;
}

.empty-wishlist {
  font-size: 15px;
  margin-top: 30px;
  margin-bottom: 0;
}

.wishes-products{
  //  padding-bottom: 20px;
}

.wishes-instruction{
  padding: 0 0 60px 0;
  clear: both;
  margin-top: 30px;

  h4{
    font-size: 21px;
    font-weight: 700;
    line-height: 26px;
    padding: 12px 0 12px 0;
  }

  > p{
    max-width: 555px; width: 100%;
    position: relative;
    line-height: 26px;
    padding: 6px 60px 6px 0;
    &.full-width{
      max-width: 100%;
    }

    span{
      color: $white;
      background-color: $green;
      display: inline-block;
      width: 38px; height: 38px;
      text-align: center;
      position: absolute; right: 0; top: 0;
      padding-top: 6px; font-size: 18px;
    }

    &:last-of-type {
      span {
        background-color: $red;
      }
    }

  }
}

@media(max-width: 560px){
  .wishes-instruction p span{top: 15px;}
}
