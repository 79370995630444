.simple-page {
  margin-top: 20px;
  margin-bottom: 60px;

  .page-title {
    font-size: 24px;
    font-weight: 700;
    color: #258CC9;
    margin-bottom: 20px;
  }
}


.text-editor{
  line-height: 1.4;

  h2{
    font-size: 21px;
    font-weight: 700;
  }

  h3{
    font-size: 18px;
    font-weight: 700;
  }

  p{
    font-size: 16px;
    margin-bottom: 0;
    font-weight: 300;
  }

  a{
    text-decoration: none;
    color: #3F99D0;
    &:hover{
      text-decoration: underline;
      color: #3F99D0;
    }
  }

  ul{
    font-weight: 300;
    padding-top:5px;
    font-size: 16px;
    padding-left: 10px;

    & li{
      position: relative;
      margin-bottom: 5px;
      &:before{
        content: "";
        position: absolute;
        top: 9px; left: -10px;
        width: 3px; height: 3px;
        border-radius: 50%;
        background-color: $black;
      }
    }
  }

  & ol{
    padding-top:5px;
    padding-left: 30px;

    & li{
      position: relative;
      margin-bottom: 5px;
    }
  }

}

