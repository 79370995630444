//PRODUCT PREVIEW
.product {

  .top-part {
    border: 1px solid $grey-light;
    padding: 10px;
    position: relative;
    @include transition-all(0.4s);
  }

  .badges {
    position: absolute;
    -webkit-backface-visibility: hidden;
    top: 0; right: 0; bottom: 0; left: 0;
    font-size: 12px;
    color: $white;
    text-align: center;

    > div {
      border-top: 5px solid;
      padding: 0 10px;
      float: left;
      display: inline-block;
      margin-top: 11px;
      span {
        display: block;
        line-height: 20px;
      }
    }

    .new {
      width: 25%;
      border-color: $blue;
      span {
        background-color: $blue;
      }
    }

    .recommended {
      width: 45%;
      border-color: $green;
      span {
        background-color: $green;
      }
    }

    .action {
      width: 30%;
      border-color: $red;
      span {
        background-color: $red;
      }
    }

    &.one-badge > div {
      float: none;
    }

    .discount {
      z-index: 1;
      -webkit-backface-visibility: hidden;
      @include transition-all(0.4s);
      position: absolute;
      bottom: 0; right: 0;
      color: $white;
      &:before {
        content: '';
        position: absolute;
        right: 0; bottom: 0;
        width: 0; height: 0;
        border-style: solid;
        border-width: 0 0 60px 60px;
        border-color: transparent transparent $red transparent;
      }

      span {
        position: relative;
        display: inline-block;
        padding: 0 7px 7px 0;
      }
    }

  }

  &:hover .top-part {
    border-color: $black;
  }

  .about {
    padding: 15px 10px;

    h3 {
      font-size: 16px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      margin-bottom: 10px;
      padding: 0 0 5px 0;
    }

    h4 {
      font-size: 14px;
      font-weight: 300;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      padding: 0 0 5px 0;
      margin-bottom: 15px;

      a {
        text-decoration: underline;
      }
    }
  }

  .options {
    font-size: 16px;
    line-height: 40px;
    border: 1px solid $grey-light;
    border-width: 1px 0;
    padding: 0 10px;

    > a {
      cursor: pointer;
      width: 30px;
      text-align: center;
      display: inline-block;

      &.quick-view {
        float: left;
      }
      &.add-to-favorites {
        float: right;
      }

      &:hover {
        color: $red;
      }
    }
  }

  .product-price {

    .new-price {
      font-size: 14px;
      color: $blue;
      margin-right: 10px;
    }

    .old-price {
      font-size: 12px;
      font-weight: 300;
      padding: 0 5px;
      position: relative;
      color: $grey;
      display: inline-block;
      &:before {
        content: '';
        position: absolute;
        left: 0; top: 50%;
        width: 100%; height: 1px;
        background-color: $grey;
      }
    }
  }
}


//PRODUCT PAGE
.product-page{
  padding-top: 20px;
  padding-bottom: 40px;
}
.product-options_holder{

  & h1{
    font-size: 24px;
    color: $black-dark;
    margin: 0 0 20px 0;
  }
  & .product-options_row{
    padding: 0 0 20px 0;
    clear: both;
  }

  & .product-price{
    font-size: 22px;
    font-weight: 700;
    color: $red;
    & .price_decimal{
      font-size: 0.6em;
      position: relative;
      top: -0.5em;
    }

    & .old-price{
      position: relative;
      font-size: 16px;
      font-weight: 400;
      padding: 0 3px;
      margin-left: 10px;
      color: $black;
      &:before{
        content: '';
        position: absolute;
        top: 50%; left: 0;
        width: 100%; height: 1px;
        background-color: $black;
      }
    }

    & .discount{
      font-size: 12px;
      font-weight: 700;
      color: $white;
      background-color: $red;
      display: inline-block;
      margin-left: 15px;
      line-height: 15px;
      padding: 3px 5px;
    }
  }


  @media (max-width: 480px) {

    h1{
      font-size: 18px;
      margin: 0 0 15px 0;
    }
    & .product-options_row{
      padding: 0 0 15px 0;
    }

    & .product-price{
      font-size: 16px;

      & .old-price{
        position: relative;
        font-size: 12px;
        font-weight: 400;
        padding: 0 3px;
        margin-left: 10px;
        color: $black;
      }
    }

  }


}
.product-options_desc{
  padding-top: 10px;
  padding-bottom: 10px;
}
.product-links{
  border-bottom: 1px solid $grey;
  float: left;
  padding: 25px 0 15px 0;
  width: 100%;

  & li{
    float: left;
    margin-right: 37px;
    &:last-child{
      margin-right: 0;
    }

    & a{
      float: left;
      padding-top: 13px;
      text-decoration: none;

      & span{
        float: left;
        font-size: 19px;
        height: 40px;
        margin: -10px 10px 0 0;
        padding: 10px 0 0;
        text-align: center;
        width: 40px;
        background-color: $blue;
        border: 1px solid $blue;
        color: $white;
        @include transition-all(0.4s);
      }

      &:hover span{
        background-color: $red;
        border-color: $red;
      }
    }

    & .addthis_toolbox.addthis_32x32_style span{line-height: 100%!important;}

  }
}
.product-options_availability{
  & .on-stock{
    color: $red;
  }
  & .out-of-stock{
    color: $green;
  }
}
.counter-and-large-button-holder{
  position: relative;
  padding-left: 107px!important;

  & .product-counter{
    //    padding-right: 30px;
    position: absolute;
    left: 0; top: 0;

    & > input{
      border: 1px solid $grey;
      font-size: 19px;
      line-height: 25px;
      padding: 9px 0;
      text-align: center;
      width: 90px;
      margin-top: 0;
    }

    & > div{
      border-left: 1px solid $grey;
      cursor: pointer;
      font-size: 15px;
      line-height: 100%;
      padding: 3px 0;
      position: absolute;
      right: 0;
      text-align: center;
      width: 30px;
      -moz-user-select: none;
      -khtml-user-select: none;
      user-select: none;
      background-color: $blue;
      color: $white;
      @include transition-all(0.4s);
      &:hover{
        background-color: $red;
      }
      &.addQuantity{
        border-bottom: 1px solid $grey;
        top: 0;
      }
      &.minusQuantity{
        bottom: 0;
      }

    }
  }
  & .large-button{
    font-size: 18px;
    color: $white;
    text-transform: uppercase;
    background-color: $blue;
    text-align: center;
    line-height: 25px;
    float: right;
    display: block;
    width: 100%;
    padding: 10px;
    @include transition-all(0.4s);
    &:hover{
      background-color: $red;
      color: $white;
    }

    & span{
      display: inline-block;
      margin-right: 10px;
      position: relative;
      top: 1px;
    }
  }

}
.share-holder{
  padding-top: 20px!important;

  & .share-title{
    display: inline-block;
    line-height: 30px;
    vertical-align: top;
    padding-right: 10px;
  }

  & ul.social-list{
    display: inline-block;

    & li{
      float: left;
      margin-right: 7px;

      & a{
        color: $white;
        background-color: $blue;
        border: 1px solid $blue;
        border-radius: 50%;
        float: left;
        font-size: 13px;
        line-height: 28px;
        display: inline-block;
        height: 30px; width: 30px;
        text-align: center;
        padding-top: 2px;
        &:hover,
        &:focus{
          background-color: $red;
          text-decoration: none;
        }
      }

    }
  }

}


.share-title{
  display: inline-block;
  line-height: 30px;
  vertical-align: top;
  padding-right: 10px;
}
ul.social-list{
  display: inline-block;
}
ul.social-list li{
  float: left;
  margin-right: 7px;
}
ul.social-list li a{
  background-color: #fff !important;
  border: 1px solid $black;
  color: $black;
  float: left;
  height: 32px;
  line-height: 30px;
  text-align: center;
  width: 32px;
  & span{
    line-height: 32px;
    display: inline-block;
  }
}
ul.social-list li a:focus,
ul.social-list li a:hover{
  color: $white;
  background-color: $blue!important;
  text-decoration: none;
  border: 1px solid $blue;
}
.stButton{
  position: static;
}
.stButton .stLarge{
  position: absolute;
  left: 0; top: 0;
  opacity: 0!important;
}
.share-this-block{
  margin: 20px 0;
}

.tabs-panel_products{
  clear: both;
  padding: 70px 0;
  & .nav-tabs{
    width: 100%;
    height: 40px;
    border: 0;
    & > li{
      display: inline-block;
      float: left;
      border: 1px solid $grey;
      border-width: 1px 1px 1px 0;
      &:first-of-type{
        border-width: 1px;
      }
      & a{
        display: block;
        line-height: 20px;
        padding: 9px 10px;
        text-align: center;
        color: $black;
        background-color: $white;
        font-size: 15px;
        font-weight: 600;
        border: 0;
        border-radius: 0;
        margin: 0;
        text-transform: uppercase;
      }
      &.active{
        & a{
          background-color: $blue;
          color: $white;
          border: 0;
        }
      }
    }
  }
  & .tab-content{
    padding: 15px 0;
    clear: both;
  }
}

.products-similar {
  padding-top: 20px;
  padding-bottom: 50px;
  background-color: #FCFCFC;

  h2 {
    font-size: 18px;
    color: $red;
    line-height: 40px;
    text-align: left;
    text-transform: uppercase;
    margin-bottom: 30px;

    span {
      display: inline-block;
      border-bottom: 2px solid $black
    }
  }
}

.products-next{
  margin-bottom: 30px;
  .position {
    padding-right: 60px;
    h2 {
      font-size: 18px;
      color: $blue;
      height: 40px;
      line-height: 20px;
      margin-bottom: 30px;
      position: relative;

      span {
        position: relative;
        max-width: 100%;
        top: 50%;
        @include translate(0, -50%);
        display: inline-block;
      }
    }
  }
  .slick-arr-top {
    right: 0;
  }
}


//PRODUCT LIST PAGE
.products-list_page {
  padding-bottom: 30px;
}
.products-list_page .product-item {
  margin: 25px 0;
  padding: 0 1px;
}

.products-list_top{
  padding: 0 0 15px 0;
  position: relative;

  &:before {
    content: '';
    position: absolute;
    left: -2000px;
    top: 0;
    width: 5000px;
    height: 100%;
    background-color: #fcfcfc;
    z-index: -1;
  }

  .search-block {
    width: auto;
    @media(min-width: 992px){
      margin-left: -30px;
    }
  }

  .products-list_options {
    margin-top: 20px;
  }


  .category-desc_trigger_holder {
    padding-right: 42px;
    text-align: right;
    margin-top: 34px;
    @media (min-width: 992px) and (max-width: 1199px) {
      margin-top: 0;
    }

    p {
      margin: 0;
      line-height: 20px;
      padding: 6px 0;
    }

    .category-desc_trigger{
      cursor: pointer;
      position: absolute;
      right: 15px; bottom: 0;
      width: 32px; height: 32px;

      & a{
        display: block;
        position: absolute;
        width: 32px; height: 32px;
        font-size: 14px;
        color: $white;
        background-color: $blue;
        &:after{
          font-family: FontAwesome;
          content: "\f067";
          top: 0; right: 0; bottom: 0; left: 0;
          position: absolute;
          text-align: center; line-height: 32px;

        }
      }

      &.opened a:after{
        content: "\f068";
        background-color: $red;
      }

      &:hover a,
      &.opened a{
        background-color: $blue;
      }
    }
  }
}

.product_listing .product {
  margin-top: 30px;
}


h1.category-desc_title {
    margin-bottom: 10px;
    font-size: 26px;
    font-weight: 700;
    color: $blue;
}

.category-desc_content{
  clear: both;
  display: none;
  padding-top: 10px;

  & h2{
    color: $blue;
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 20px;
  }

  & p{
    margin-bottom: 20px;
    font-size: 15px;
  }
}


.select-filter{
  display: block;
  position: relative;
  padding-top: 5px;

  & .select-field {
    border: 1px solid $grey;
    font-size: 13px;
    line-height: 20px;
    display: inline-block;
    height: 32px;
    padding: 5px 40px 4px 10px;
    width: 100%;
    border-radius: 0;
    cursor: pointer;
  }

  & i {
    margin-top: 0;
    position: absolute;
    right: 0;
    top: 5px;
    width: 32px;
    height: 32px;
    background-color: $blue;
    color: $white;
    text-align: center;
    line-height: 32px;
    -webkit-transition: all .3s; /* Safari */
    transition: all .3s;
    pointer-events: none;
  }

  &:hover i{
    background-color: $blue;
  }
}

.price-range_holder{
  float: left;
  width: 100%;
  margin-top: 5px;
  padding: 12px 16px 0 16px;
  height: 32px;

  & .slider-range{
    background: $white;
    border: 1px solid $grey;
    float: left;
    height: 7px;
    margin-bottom: 40px;
    width: 100%!important;
    border-radius: 4px;
    -moz-border-radius: 4px;
    -webkit-border-radius: 4px;

    & .ui-slider-range{
      background: transparent;
      border: 0 none;
      height: 7px;
      border-radius: 0;
      -moz-border-radius: 0;
      -webkit-border-radius: 0;
    }



    & .ui-state-focus,
    & .ui-state-hover,
    & .ui-state-active,
    & .ui-slider-handle{
      background: $grey;
      border: 0;
      cursor: pointer;
      height: 32px;
      margin-left: -16px;
      top: -13px;
      width: 32px;
      border-radius: 0;
      -moz-border-radius: 0;
      -webkit-border-radius: 0;
    }

    & .ui-slider-handle{
      &:before{
        content: "\f105";
        font-family: "FontAwesome";
        font-size: 20px;
        width: 32px;
        line-height: 32px;
        position: absolute; top: 0; left: 0;
        color: $white;
        text-align: center;
        background-color: $blue;
      }
      &:nth-of-type(2):before{
        content: "\f104";
      }
      &:hover,
      &:hover:before,
      & .ui-state-active:before,
      & .ui-state-active{
        background-color: $red;
      }
    }
  }
}

.option-title{
  font-size: 18px;
  color: $red;
  display: inline-block;
  line-height: 30px;
  text-transform: uppercase;
  margin-bottom: 0;
  @media (min-width: 992px) and (max-width: 1199px) {
    margin-top: 10px;
  }

  @media (max-width: 480px) {
    &.not-first {
      margin-top: 20px;
    }
  }

}

input.slider-input{
  border: 0 none;
  float: right;
  line-height: 30px;
  max-width: 140px;
  font-size: 14px;
  font-weight: 700;
  background: transparent;
  padding: 0;
  text-align: right;

  @media (min-width: 992px) and (max-width: 1199px) {
    margin-top: 10px;
  }
}

.product-view-type{
  margin-top: 5px;
  & span{
    display: inline-block;
    width: 32px; height: 32px;
    background: url("../images/product-view-type.png") no-repeat;
    cursor: pointer;

    &.type-grid{
      background-position: left top;
      margin-right: 15px;
      &:hover,
      &.active{
        background-position: left bottom;
      }
    }

    &.type-list{
      background-position: right top;
      &:hover,
      &.active{
        background-position: right bottom;
      }
    }

  }
}

.visible-in-list-view{
  display: none;
}

@media (min-width: 767px) and (max-width: 991px), (min-width: 1200px) {
  .product_listing.on-product-page > li:nth-of-type(3n+1){
    clear: both;
  }
}
@media (max-width: 767px), (min-width: 992px) and (max-width: 1199px) {
  .product_listing.on-product-page > li:nth-of-type(2n+1){
    clear: both;
  }
}

@media(max-width: 991px){
  .product_listing.on-product-page > li:nth-of-type(3n+1){
    clear: both;
  }
}
@media(min-width: 768px) and (max-width: 991px){
  .wishes-products .product_listing > li:nth-of-type(2n+1){
    clear: none;
  }
  .wishes-products .product_listing > li:nth-of-type(3n+1){
    clear: both;
  }
}
@media(min-width: 992px){
  .product_listing > li:nth-of-type(3n+1){
    clear: both;
  }
  .wishes-products .product_listing > li:nth-of-type(3n+1){
    clear: none;
  }
  .wishes-products .product_listing > li:nth-of-type(4n+1){
    clear: both;
  }
}

@media(min-width: 992px){

  .product_listing.list-view{
    & > li{
      width: 100%;
    }
    & .product-item{
      max-width: 100%;
      margin: 25px 0 0 0;
      padding-bottom: 25px;
      border-bottom: 1px solid $grey;
      &:hover .badges {
        bottom: 0;
      }
    }
    & .product-image-holder{
      width: 260px;
      float: left;
    }
    & ul.product-item_info{
      display: none!important;
    }

    & .product-data-holder{
      width: 585px;
      float: left;
      padding-left: 30px;
    }
    & .product-item_title{
      font-size: 22px;
    }
    & .visible-in-list-view{
      display: block;
    }
    & .product-data{
      border-bottom: 0;
      padding: 0;
      text-align: left;
      min-height: 210px;
    }
    & .product-data_link{
      text-align: left;
    }
    & .product-item_price{
      text-align: left;
    }
    & .short_desc{
      line-height: 167%;
    }

    & .product-item_options{
      display: block;
      & li{
        display: block;
        width: 33.33%;
        float: left;
        border-right: 1px solid $white;
        &:last-of-type{
          border: none;
        }
        & a{
          display: block;
          font-size: 18px;
          line-height: 30px;
          padding: 10px 0;
          background-color: $blue;
          color: $white;
          text-align: center;
          & span{
            display: inline-block;
            margin-right: 10px;
          }
          &:hover{
            background-color: $blue;
            color: $white;
          }
        }
      }
    }
  }

}
@media(min-width: 992px) and (max-width: 1199px){

  .product_listing.list-view{
    & .product-image-holder{
      width: 210px;
    }
    & .product-data-holder{
      width: 485px;
    }
    & .product-data{
      min-height: 160px;
    }

    & .product-item_options a{
      font-size: 14px!important;
    }
  }

}

@media (max-width: 991px) {
  .product-options_holder {
    margin-top: 20px;
  }
}