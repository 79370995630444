.news-prev {
   margin-top: 40px;
   padding-bottom: 50px;
   border-bottom: 1px solid $grey-light;

   h2 {
     font-size: 18px;
     color: $red;
     line-height: 40px;
     text-transform: uppercase;
     margin-bottom: 40px;
     border-bottom: 2px solid $black;

     span {
       display: inline-block;
     }
   }

   .news-prev-item {
     position: relative;
   }

   .news-info {
     margin-top: 50px;
     padding-bottom: 40px;
     position: relative;
     height: 140px;

     .read-more {
       position: absolute;
       bottom: 0; right: 0;
       background-color: #F5F5F5;
       width: 110px;
       text-align: center;
       line-height: 30px;

       &:before {
         content: '';
         position: absolute;
         height: 30px; width: 10px;
         right: 100%; top: 0;
         background-color: $red;
         @include transition-all(0.2s);
       }

       &:hover {
         background-color: $red;
         color: $white;

         &:before {
           width: 0;
         }
       }
     }
   }

   h3 {
     font-size: 14px;
     margin-bottom: 10px;
   }

   .news-desc {
     font-weight: 300;
   }
 }

.author-date-holder {
  position: absolute;
  top: 85px; right: 20px;
  background-color: $white;
  border-bottom: 2px solid $blue;
  padding: 12px 15px 0 40px;
  line-height: 20px;
  height: 70px;

  > span {
    display: block;
    position: relative;
    margin-bottom: 5px;
    font-weight: 300;
  }

  i {
    position: absolute;
    left: -40px; top: 0;
    display: block;
    width: 40px;
    text-align: center;
    font-weight: 400;
    color: $red;
    line-height: 20px;
  }
}

.news-list-page {

  .article-preview {
    @include clearfix;
    position: relative;
    clear: both;
    border: 1px solid $grey-light;
    margin-top: 30px;

    .image-holder {
      position: relative;
      float: left;
      width: 50%;
      height: 280px;
      z-index: 1;
    }

    .text-holder {
      position: relative;
      float: left;
      width: 50%;
      height: 280px;
      padding: 20px 0 20px  30px;

      h3 {
        a {
          text-transform: uppercase;
          font-size: 16px;
          font-weight: 400;
          border-left: 10px solid $blue;
          padding-left: 10px;
          color: $black;
          line-height: 30px;
          display: block;
          @include transition-all(0.4s);
          &:hover {
            background-color: $blue;
            color: $white;
          }
        }
      }

      .about-article {
        margin-top: 10px;
        margin-bottom: 25px;

        > div {
          display: inline-block;
          font-size: 14px;
          span {
            color: $red;
            display: inline-block;
            padding-right: 10px;
          }
          a {
            font-weight: 300;
          }

          &:nth-of-type(1) {
            margin-right: 30px;
          }
        }
      }

      .short-text {
        padding-right: 30px;
        font-size: 16px;
        font-weight: 300;
      }

      a.read-more {
        position: absolute;
        bottom: 20px; right: 30px;
        background-color: #F5F5F5;
        color: $black;
        width: 110px;
        text-align: center;
        line-height: 30px;

        &:before {
          content: '';
          position: absolute;
          height: 30px; width: 10px;
          right: 100%; top: 0;
          background-color: $red;
          @include transition-all(0.2s);
        }

        &:hover {
          background-color: $red;
          color: $white;

          &:before {
            width: 0;
          }
        }
      }
    }
  }
}

.news-sidebar {

  .blog-categories {
    margin-top: 30px;
    margin-bottom: 30px;

    .title {
      background-color: $blue;
      color: $white;
      display: table;
      width: 100%;
      height: 60px;
      padding: 0 80px 0 20px;
      position: relative;

      p {
        line-height: 20px;
        display: table-cell;
        vertical-align: middle;
      }

      span {
        font-size: 18px;
        position: absolute;
        display: inline-block;
        width: 60px;
        text-align: center;
        @include translate(0, -50%);
        top: 50%; right: 0;
      }
    }

    ul {
      padding: 0 20px;
      border: 1px solid $blue;

      > li {
        display: block;
        border-bottom: 1px solid $grey-light;
        &:last-of-type {
          border-bottom: 0;
        }

        a {
          display: block;
          line-height: 20px;
          padding: 15px 0;
          font-size: 14px;
          font-weight: 300;
          &:hover {
            color: $blue;
          }
        }
      }
    }
  }

  .the-most-popular {
    margin-top: 30px;
    margin-bottom: 30px;

    .title {
      background-color: #f5f5f5;
      display: table;
      width: 100%;
      height: 60px;
      padding: 0 80px 0 20px;
      position: relative;

      p {
        line-height: 20px;
        display: table-cell;
        vertical-align: middle;
      }

      span {
        font-size: 18px;
        position: absolute;
        display: inline-block;
        width: 60px;
        text-align: center;
        @include translate(0, -50%);
        top: 50%; right: 0;
      }
    }

    ul {

      > li {
        display: block;
        position: relative;
        padding-left: 80px;
        margin-top: 20px;
        min-height: 70px;

        .image-holder {
          position: absolute;
          left: 0; top: 0;
          width: 70px; height: 70px;
        }

        .text-holder {
          h4 {
            margin-bottom: 10px;
            a {
              text-transform: uppercase;
              font-size: 14px;
              font-weight: 400;
            }
          }
          .about-article {
            font-size: 10px;
            font-weight: 300;
          }
        }
      }
    }
  }
}

.news-content {
  margin-top: 30px;

  .main-img-holder {
    position: relative;
    margin-bottom: 55px;
    > div.cover-bg {
      height: 280px;
    }
  }

  .author-date-holder {
    top: auto;
    bottom: -35px;
  }

  .text {
    h1 {
      font-size: 18px;
      line-height: 30px;
      border-left: 10px solid $blue;
      font-weight: 400;
      padding-left: 10px;
      margin-bottom: 30px;
    }
  }

  .social{
    border-bottom: 1px solid $grey-light;
    padding: 15px 0 10px 0;
    margin-top: 25px;
    margin-bottom: 25px;
  }
  .social .soc-text{
    line-height: 20px;
    vertical-align: top;
  }
  .addthis_toolbox{
    display: inline-block;
  }

  .social .addthis_32x32_style .addthis_counter.addthis_bubble_style:hover, .social .addthis_32x32_white_style .addthis_counter.addthis_bubble_style:hover{
    background-position: 0 0!important;
  }
}

/* Leave a Reply */
.blog-comments{
  margin-bottom: 50px;
  .blog-comments_item{
    padding: 30px 10px 30px 120px;
    position: relative;
    border-bottom: 1px solid $grey-light;
    min-height: 150px;
  }
  .blog-comment_text{
    font-size: 16px;
    font-weight: 300;
  }
  .comment-user{
    margin-bottom: 5px;
    font-size: 16px;
  }
  .comment-date{
    margin-bottom: 5px;
    font-size: 14px;
    font-weight: 300;
  }
  .avatar {
    width: 90px; height: 90px;
    position: absolute;
    left: 0; top: 15px;
  }

  @media (max-width: 480px) {
    margin-bottom: 30px;
    .blog-comments_item{
      padding: 20px 10px 20px 90px;
    }
    .blog-comment_text{
      font-size: 14px;
    }
    .comment-user{
      font-size: 14px;
    }
    .comment-date{
      font-size: 12px;
    }
    .avatar {
      width: 70px; height: 70px;
    }
  }
}

.comment-form_holder {
  margin-top: 30px;
  margin-bottom: 30px;

  .email-no-display {
    font-size: 16px;
    font-weight: 300;
  }

  .bottom-part button {
    margin-top: 0;
  }
}

.comment-form_holder .rules{
  position: absolute;
  right: 10px;
  top: 30px;
  text-decoration: underline;
  color: $red;
}
.comment-form_holder #contactForm {
  padding-bottom: 0px;
}

.comment-form_heading {
  font-size: 36px;
  font-weight: 400;
  @media(max-width: 480px) {
    font-size: 26px;
  }
}

.reply-field{
  border-radius: 0;
  background-color: $white;
  border: 1px solid $grey-light;
  line-height: 20px;
  padding: 9px 10px;
  width: 100%;
  margin-bottom: 15px;
  color: $black;
  font-size: 12px;
  font-weight: 400;
  &::-webkit-input-placeholder {
    color: $black;
    font-weight: 300;
  }

  &:-moz-placeholder { /* Firefox 18- */
    color: $black;
    font-weight: 300;
  }

  &::-moz-placeholder {  /* Firefox 19+ */
    color: $black;
    font-weight: 300;
  }

  &:-ms-input-placeholder {
    color: $black;
    font-weight: 300;
  }
}
textarea.reply-field{
  min-height: 150px;
}
