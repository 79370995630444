.bellow-header {
  position: relative;

  .positioning-helper {
    padding: 60px 0 0 260px;
    position: absolute;
    top: 0; left: 0;
    height: 100%; width: 100%;
  }

  @media (max-width: 991px) {

    .positioning-helper {
      padding: 60px 0 0 0;
      position: relative;
      height: 360px;
    }

    .search-block.search-all {
      padding-left: 0;
    }

    .categories-block {
      display: none;
    }
  }
}

.search-block {
  width: 100%; height: 60px;
  position: relative;

  &.search-all {
    position: absolute;
    left: 0; top: 0;
    padding-left: 260px;
  }

  input {
    width: 100%;
    height: 60px;
    border: 5px solid #f5f5f5;
    line-height: 30px; padding: 10px 65px 10px 15px;
  }

  button {
    position: absolute;
    top: 5px; right: 5px;
    width: 50px; height: 50px;
    background-color: $red;
    border: 0; box-shadow: none;
    font-size: 18px; color: $white;
  }
}