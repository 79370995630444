.categories-block{
  &.categories-block-t2 {
    width: 100%;
  }
  position: relative;
  width: 260px;
  border: 1px solid $blue;
  z-index: 1;
  &:hover{
    z-index: 3;
  }

  .categories-block-title{
    color: $white;
    line-height: 30px;
    font-size: 18px;
    height: 60px; padding: 15px 15px;
    background: $blue;
    margin: 0;

    span {
      float: right;
      line-height: 30px;
      font-size: 18px;
    }
  }

  > ul {
    padding: 10px 0;
  }

  li{
    position: relative;
    display: block;
    padding: 0 20px;
  }

  a{
    display: block;
    color: $black;
    &:hover,
    &:focus{
      text-decoration: none;
    }
  }

  li.active > a{
    color: $red;
  }

  .categories_level_1{

    i{
      position: absolute;
      -ms-transform: translate(0,-50%); /* IE 9 */
      -webkit-transform: translate(0,-50%); /* Safari */
      transform: translate(0,-50%);
      right: 25px; top: 50%;
      line-height: 20px;
      color: $red;
    }

    > li{
      position: relative;
      height: 40px;
      display: table;
      width: 100%;
      &:hover .categories_level_2{
        left: 100%;
        opacity: 1;
        transform: scaleY(1);
        visibility: visible;
        & > li > a{
          display: block;
        }
      }

      > a {
        display: table-cell;
        vertical-align: middle;
        line-height: 20px;
        padding: 0 15px 0 0;
        border-bottom: 1px solid #ddd;
        font-weight: 300;
      }

      &:hover > a,
      &:focus > a{
        color: $blue;
        text-decoration: none;
      }

      &.action{
        > a{
          background-color: #FFDCD2;
          color: $red;
          &:hover,
          &:focus{
            background-color: #FF592B;
            color: $red;
          }
        }

        i{
          color: $color1;
        }
      }
      &:last-of-type > a {
        border: 0;
      }
    }
  }

  .categories_level_2{
    position: absolute;
    left: 100%; top: 0;
    width: 260px;
    background-color: $white;
    /* Firefox v3.5+ */
    -moz-box-shadow:3px 3px 4px rgba(0,0,0,0.2);
    /* Safari v3.0+ and by Chrome v0.2+ */
    -webkit-box-shadow:3px 3px 4px rgba(0,0,0,0.2);
    /* Firefox v4.0+ , Safari v5.1+ , Chrome v10.0+, IE v10+ and by Opera v10.5+ */
    box-shadow:3px 3px 4px rgba(0,0,0,0.2);
    -ms-filter:"progid:DXImageTransform.Microsoft.dropshadow(OffX=3,OffY=3,Color=#33000000,Positive=true)";
    filter:progid:DXImageTransform.Microsoft.dropshadow(OffX=3,OffY=3,Color=#33000000,Positive=true);
    min-height: 0px;
    overflow: hidden;
    padding: 10px 0;
    border: 1px solid $blue;
    opacity: 0;
    //    transform: scaleY(0);
    transform-origin: 0 0 0;
    transition: all 0.5s ease 0s;
    visibility: hidden;
    z-index: 5;
    @include transition-all(0.4s);

    i{
      display: none;
    }

    > li{
      margin-bottom: 5px;
      padding: 0 10px;

      > a{
        padding: 5px;
        font-weight: 300;
      }
      &:hover > a,
      &:focus > a{
        background-color: $blue;
        color: $white;
        text-decoration: none;
      }
    }
  }

}




#cat-acc{
  clear: both;
  margin-bottom: 30px;
  padding: 10px 20px;
  background-color: $white;
  width: 100%;

  > div:first-of-type h3 {
    border: 0;
  }

   h3 {
    margin: 0;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    padding: 10px 30px 10px 0;
    position: relative;
    border: none;
    border-top: 1px solid #ddd;

    > a {
      color: $black;
    }

    > span {
      display: inline-block;
      width: 30px;
      height: 40px;
      cursor: pointer;
      position: absolute;
      right: 0; top: 0;
      font-size: 14px;
      color: $red;
      text-align: center;
      line-height: 40px;
      @include transition-all(0.1s);
    }

    &:hover {
      > a{
        color: $red;
      }
    }
  }

  ul {
    display: none;
    margin-left: 20px;

    > li {
      &:hover {
        a {
          color: $blue;
        }
      }

      a {
        color: $black;
        display: block;
        font-size: 14px;
        font-weight: 300;
        line-height: 20px;
        padding: 5px 0;
        position: relative;
        &:before {
          content: "\f0da";
          font-family: FontAwesome;
          line-height: 30px;
          display: inline-block;
          width: 20px;
          position: absolute;
          left: -20px; top: 0;
          font-size: 14px;
          color: $blue;
        }
      }

      &:last-of-type {
        background-image: none;
      }
    }
  }

  .open-category {

    h3 {

      > a {
        color: $red;
      }

      > span {
        @include transform(rotate(90deg));
      }
    }
  }
}