/* product_quick_view */
#product_quick_view_div{position:fixed; top:0; z-index:9999; width:100%; height:100%; background:rgba(0,0,0,0.7);}
#pqv_inner_div{position:absolute; left:50%; top:50%; width:900px; height:auto; background:#FFF; padding:10px;
  -ms-transform: translate(-50%,-50%); /* IE 9 */
  -webkit-transform: translate(-50%,-50%); /* Safari */
  transform: translate(-50%,-50%);
  max-width: 90%;
}
#pqv_inner_div .product_thumb .frame{padding: 3px; border: 1px solid $grey;}
#pqv_inner_div .product_thumb .frame>div{
  position: relative;
}
#pqv_inner_div .product_thumb img:not(.prop-holder){
  position: absolute;
  top: 50%;
  left: 50%;
  display: block;
  width: auto;
  max-width: 100%;
  height: auto;
  max-height: 100%;
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%)
}
#pqv_inner_div .product-options_header h3{font-size: 18px; margin: 5px 0 10px 0; }
#pqv_inner_div .product-options_cart{padding: 20px 0;}
#pqv_inner_div .product-item_price{text-align: left;}
#pqv_inner_div ul.product-links {border-bottom: 0; padding-bottom: 0px;}
#pqv_inner_div .product-options_desc{}
#pqv_inner_div .close{ position:absolute; right: -17px; top: -17px; text-indent:-99999px; opacity: 0.85; background: url("../../assets/images/close-quick.png") ; width:45px; height:45px;}
#pqv_inner_div .close:hover{opacity: 1}

#pqv_inner_div .counter-and-large-button-holder{margin-top: 20px;}
