.three-links {

  margin: 30px 0 60px 0;

  .block {
    background-color: $blue;
    color: $white;
    padding: 30px 15px 30px 90px;
    position: relative;
    height: 130px;
  }

  .icon {
    font-size: 48px;
    display: inline-block;
    width: 90px;
    text-align: center;
    position: absolute;
    top: 25px; left: 0;
  }

  .text {
    font-weight: 300;
  }

  .link {
    display: inline-block;
    position: absolute;
    background-color: $white;
    color: $blue;
    border-bottom: 1px solid $grey-light;
    width: 200px;
    text-align: center;
    line-height: 30px;
    padding: 15px 0;
    right: 30px; bottom: -30px;

    &:hover {
      border-bottom: 1px solid $black;
    }
  }


  @media (max-width: 600px) {
    .col-xs-4 {
      width: 100%;
    }

    .block {
      margin-bottom: 30px;
      padding: 10px 10px 30px 60px;
      height: auto;
      min-height: 80px;
    }

    .icon {
      font-size: 32px;
      display: inline-block;
      width: 60px;
      text-align: center;
      position: absolute;
      top: 10px; left: 0;
    }

    .link {
      width: 90%;
      line-height: 20px;
      padding: 10px 0;
      right: 5%; bottom: -10px;
    }
  }

  @media (min-width: 601px) and (max-width: 991px) {

    .block {
      padding: 10px 10px 30px 60px;
      height: 120px;
    }

    .icon {
      font-size: 32px;
      display: inline-block;
      width: 60px;
      text-align: center;
      position: absolute;
      top: 10px; left: 0;
    }

    .link {
      width: 90%;
      line-height: 20px;
      padding: 10px 0;
      right: 5%; bottom: -10px;
    }
  }

  @media (min-width: 601px) and (max-width: 767px) {
    .block {
      height: 160px;
      padding: 50px 10px 30px 10px;
      text-align: center;
    }

    .icon {
      font-size: 32px;
      display: inline-block;
      width: 60px;
      text-align: center;
      position: absolute;
      top: 10px; left: 50%;
      @include translate(-50%, 0);
    }
  }
}