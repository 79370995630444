.newsletter-form {
  margin-top: 30px;

  .top-block{
    background-color: $red;
    padding: 20px;
  }

  .text {
    position: relative;
    padding-left: 60px;
    color: white;
    font-size: 16px;

    .desc {
      height: 60px;
      display: table;

      p {
        display: table-cell;
        vertical-align: middle;

        @media (max-width: 600px) {
          font-size: 14px;
        }
      }
    }

    .envelope-icon {
      position: absolute;
      left: 0; top: 50%;
      @include translate(0, -50%);
      width: 44px; height: 60px;
      background: url('../images/envelope-icon.png') no-repeat center;
    }
  }

  .input-holder {
    padding-right: 200px;
    position: relative;

    input {
      width: 100%;
      border: 0;
      line-height: 30px;
      padding: 15px 20px;
      background-color: $white;
      font-size: 16px;
      font-weight: 300;
    }

    button {
      position: absolute;
      right: 0; top: 0;
      height: 60px; width: 200px;
      border: 0; box-shadow: none;
      background-color: $green;
      color: $white;
      font-size: 18px;
      font-weight: 700;
    }

    @media (max-width: 600px) {
      padding-bottom: 60px;
      padding-right: 0;

      input {
        margin-top: 10px;
        line-height: 30px;
        padding: 5px 10px;
      }

      button {
        right: 0; bottom: 0; top: auto;
        height: 40px; width: 100%;
      }
    }
  }

  .bottom-block {
    margin-top: 20px;

    .social {
      line-height: 32px;
      padding: 20px 0;
      font-size: 16px;
      display: inline-block;

      .social-text {
        margin: 0 5px 0 0;
        display: inline-block;
        font-size: 21px;
      }

      .social-link {
        margin-left: 10px;
        cursor: pointer;
        font-size: 28px;
        line-height: 41px;
        display: inline-block;
        width: 45px; height: 45px;
        border-radius: 50%;
        border: 2px solid;
        color: $white;
        text-align: center;
        @include transition-all(0.4s);

        &.facebook {
          background-color: #2B3990;
          border-color: #2B3990;
        }
        &.twitter {
          background-color: #27AAE1;
          border-color: #27AAE1;
        }
        &.instagram {
          background-color: #BE1E2D;
          border-color: #BE1E2D;
        }
        &.google {
          background-color: #ED3237;
          border-color: #ED3237;
        }

        &:hover {
          background-color: transparent;
          &.facebook {
            color: #2B3990;
          }
          &.twitter {
            color: #27AAE1;
          }
          &.instagram {
            color: #BE1E2D;
          }
          &.google {
            color: #ED3237;
          }
        }
      }

      @media(max-width: 767px) {
        .social-text {
          font-size: 16px;
        }

        .social-link {
          margin-left: 5px;
          font-size: 18px;
          line-height: 26px;
          width: 30px; height: 30px;
        }
      }
    }
  }
}