.captcha_div {

  label {
    margin-top: 40px;
  }
}

.custom-captcha-holder {
  text-align: right;

  .g-recaptcha {
    margin-top: 20px;
    display: inline-block;
    width: 304px;

    @media (max-width: 480px) {
      -webkit-transform: scale(0.77);
      -moz-transform: scale(0.77);
      -ms-transform: scale(0.77);
      -o-transform: scale(0.77);
      transform: scale(0.77);
      -webkit-transform-origin: 0 0;
      -moz-transform-origin: 0 0;
      -ms-transform-origin: 0 0;
      -o-transform-origin: 0 0;
      transform-origin: 0 0;
      width: 235px;
    }
  }
}

.newsletter-captcha.custom-captcha-holder .g-recaptcha {
  margin-top: 0;
}

.newsletter-error {
  p {
    margin-bottom: 20px;
    font-size: 18px;
    color: $red;
    font-weight: 700;
    text-align: right;
  }

  @media (min-width: 768px) and (max-width: 991px),
  (min-width: 1200px) {
    display: table;
    width: 100%;
    height: 80px;

    p {
      display: table-cell;
      margin: 0;
      vertical-align: middle;
    }
  }
}

.contact-captcha {
  display: inline-block;
  float: right;
}