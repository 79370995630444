.user-profile-page {

  .subheading {
    font-size: 21px;
    font-weight: 700;
    padding: 0;
    margin: 0 0 25px 0;
    line-height: 1.2;
    margin-top: 30px;
    color: $black;
  }
}

.about-user {

  .input-label {
    line-height: 20px;
    padding: 0;
    font-size: 15px;
    font-weight: 400;
    margin-top: 10px;
    margin-bottom: 0;
    @media (max-width: 767px) {
      top: 10px;
      margin-top: 0;
    }
  }

  input,
  select{
    border: none;
    line-height: 20px;
    padding: 0;
    margin-top: 10px;
    color: #258DC9;
    font-size: 17px;
    margin-bottom: 0;
    @include placeholder{
      color: #258DC9;
    }
  }

  input {
    padding-left: 5px;
  }
}