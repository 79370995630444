#my-transactions{
  & .page-title{
    font-size: 21px;
    font-weight: 700;
    margin: 30px 0 20px 0;
    @media(max-width: 991px){
      margin-bottom: 15px;
    }
  }

  .list_of_products_in_cart tbody tr:last-of-type {
    background-color: transparent;
  }
}

.order_item{
  margin-bottom: 40px;
}

#my-transactions .list_of_products_in_cart {

  & tr:last-of-type td{
    padding: 20px 0;
  }

  & th:last-child,
  & tr>td:last-child{
    text-align: right;
    padding-right: 20px;
  }
}

.single-order-total{

  & .order{
    font-size: 17px;
    text-transform: uppercase;
    text-align: center;
    margin: 17px 0 0 0;
    @media(min-width: 992px) and (max-width: 1199px){
      margin: 3px 0 0 0;
    }
  }

  & .status{
    font-size: 17px;
    color: #fff;
    text-transform: uppercase;
    text-align: center;
    line-height: 50px;
    padding-left: 20px;
    float: left;
    &.status1{background-color: $red; }
    &.status2{background-color: #D9A300;}
    &.status3{background-color: #D9D900;}

    & span{
      border-left: 1px solid $white;
      line-height: 50px;
      padding: 0 20px;
      display: inline-block;
      margin-left: 10px;}
  }

  & .total_text{
    line-height: 50px;

    & .product_total{
      font-size: 22px;
      color: $red;
    }
  }

}

.print-slips:hover{
  color: $blue!important;
}

#my-transactions .list_of_products_in_cart tr:nth-last-of-type(2){border-bottom: 1px solid $grey;}

@media(max-width: 767px){
  #my-transactions .list_of_products_in_cart tr>td:last-child { padding-right: 0; }
  .single-order-total:before{display: none;}

  /* MY ORDERS */
  .order_item {padding-bottom:0; margin-bottom:40px;}
  .order_item .product_quantity{padding: 13px 10px;}
  .order_item .product_quantity .quantity_no{padding-left: 5px;}
  #my-transactions .list_of_products_in_cart tr.product_row>td:last-child{text-align: left;}

  .order_item td.total_price{width:auto; border-top: 1px solid #ddd; text-align:left !important;}
  .order_item td.total_price:nth-of-type(1)::before{display:none;}
  .order_item td.total_price .order{float:none; clear:both; text-align:left; display:table; width:100%;}
  .order_item td.total_price .order .date{display:inline-block; margin-bottom:10px;}
  .order_item td.total_price a{float:none; clear:both; display:block; margin:0 20px; }
  .order_item td.total_price .total_text{padding-left:20px;}
  .order_item td.total_price .total_text, .order_item td.total_price .total_number{float:none; clear:both; display:inline-block; margin-top:15px; text-align:left;}
}

@media screen and (max-width: 991px){
  section.main_product_info .product_description h1 {
    margin-top: 20px;
  }
  .single-order-total .order{margin: 0; float: none; display: block; text-align: center;}

  .reset_sign_in_container .xs-block{
    float: none;
    width: 100%;
  }


  .single-order-total .order,
  .single-order-total .status{ float: none; margin: 0; display: block; width: 100%; text-align: center; font-size: 16px; padding: 0; margin-bottom: 10px;}
  .single-order-total .order span,
  .single-order-total .status span{display: inline-block; width: 38%; text-align: center; float: right; margin-left: 0; padding: 0;}

  .single-order-total .order{line-height: 50px}
  .single-order-total .transaction-price{margin-right: 15px;}
  #my-transactions .list_of_products_in_cart tr:last-of-type td{padding: 15px 0;}


}

.notifications-block{
  padding: 10px;
  border: 2px solid $blue;
  display: inline-block;
}