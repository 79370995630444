.tabs-products {
  margin-top: 120px;
  margin-bottom: 50px;

  .tabs-holder {
    text-align: center;

    li {
      display: inline-block;
      margin: 0 25px;
      padding-bottom: 15px;

      a {
        font-size: 16px;
        position: relative;
        display: inline-block;
        &:hover:before {
          content: '';
          position: absolute;
          bottom: -15px; left: 0;
          height: 2px; width: 100%;
          background-color: $grey;
        }
      }

      &.active a {
        color: $red;
        &:before {
          content: '';
          position: absolute;
          bottom: -15px; left: 0;
          height: 2px; width: 100%;
          background-color: $red;
        }
      }
    }
  }

  .product {
    margin-top: 40px;
  }

  @media (max-width: 991px) {
    margin-top: 80px;
    margin-bottom: 40px;
  }


  @media (min-width: 481px) and (max-width: 600px ) {
    .tabs-holder {

      li {
        margin: 0 10px;
        padding-bottom: 10px;

        a {
          font-size: 16px;
          &:hover:before {
            bottom: -10px;
          }
        }

        &.active a {
          &:before {
            bottom: -10px;
          }
        }
      }
    }
  }

  @media (max-width: 480px) {
    .tabs-holder {

      li {
        margin: 0;
        float: left;
        width: 50%;

        a {
          font-size: 12px;
          &:hover:before {
            bottom: -10px;
          }
        }

        &.active a {
          &:before {
            bottom: -10px;
          }
        }
      }
    }
  }
}