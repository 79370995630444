/*TOP PART*/
#checkout_process{
  position: relative;
  padding: 7px;

  @media (max-width: 991px) {
    margin-top: 20px;
  }
  @media (max-width: 480px) {
    margin-top: 10px;
  }

  &:before{
    content: "";
    position: absolute;
    left: -1200px; top: 0;
    width: 4000px; height: 94px;
    background: $green;
  }

  & a, a:hover, a:focus{
    color: $black;
    text-decoration: none;
  }

  & span{
    font-size: 15px;
    display: block;
    text-transform: none;
    margin-top: 7px;
  }

  & li{
    position: relative;
    font-size: 21px;
    display: inline-block;
    width: 25%; height: 80px;
    float: left;
    margin-bottom: 15px;
    padding: 15px 0 0 70px;
    text-transform: uppercase;
    cursor: pointer;
    &:before{
      position: absolute;
      left: 22px; top: 22px;
      font-family: FontAwesome;
      font-size: 30px;
      color: $black;
    }
    &:nth-child(1):before{content: "\f07a";}
    &:nth-child(2):before{content: "\f007";}
    &:nth-child(3):before{content: "\f0d1";}
    &:nth-child(4):before{content: "\f00c";}

    &.current{
      color: $black;
      background-color: $white;
      &:before{
        color: $black;
      }

      & a, a:hover, a:focus{
        color: $black;
      }
    }
  }

}

@media(min-width: 992px) and (max-width: 1199px){
  #checkout_process li{
    font-size: 18px;
    padding: 20px 0 0 70px;

    & span{
      font-size: 14px;
    }
  }
}
@media(min-width: 768px) and (max-width: 991px){
  #checkout_process li{
    padding: 22px 0 0 55px;
    font-size: 14px;
    background-position: 0 7px;
    &:before{
      left: 15px;
    }

    & span{
      font-size: 13px;
    }
  }
}
@media(max-width: 767px){
  #checkout_process li{
    display:none;
    &.current{
      display:block;
      width:100%;
      font-size: 16px;
      text-align: center;
      padding: 20px 0px;
    }

    & span{
      font-size: 13px;
    }
  }
}


/* My cart list */
.list_of_products_in_cart a, .list_of_products_in_cart a:hover, .list_of_products_in_cart a:focus {color: inherit; text-decoration: none;}

.list_of_products_in_cart table{width:100%; border-collapse: collapse; border: 0; }
.list_of_products_in_cart thead{border-bottom: 0; height:38px;}
.list_of_products_in_cart thead tr{padding:0; margin:0; list-style:none; border-bottom: 0;}
.list_of_products_in_cart thead tr th{font-size:15px; font-weight: 700; text-align: center; text-transform:uppercase; line-height:38px;}
.list_of_products_in_cart thead tr th:nth-child(1){text-align:left; width: 340px;}
.list_of_products_in_cart thead tr th:last-of-type{text-align: right; padding-right: 10px;}

.list_of_products_in_cart tbody{background: #fcfcfc;}
.list_of_products_in_cart tr{border-bottom: 1px solid #fcfcfc; }
.list_of_products_in_cart tbody tr{background-color: #fcfcfc;}
.list_of_products_in_cart tbody tr .single-order-total{background-color: #fcfcfc;}
.list_of_products_in_cart tbody>tr.product_row{border-left: 0; border-right: 0;}
.list_of_products_in_cart tbody>tr.product_row:first-of-type{border-top: 0;}
.list_of_products_in_cart tbody>tr.product_row:last-of-type{border-bottom: 0;}
.list_of_products_in_cart td{text-align:center;}
.product_info{display:table;}
.product_info .thumb{width: 92px; height: 92px; display: inline-block; background-color: $white; border: 1px solid $grey-light; margin: 10px; text-align: center; vertical-align: middle; position: relative;}
.product_info .thumb img{width: auto; height: auto; max-width: 90px; max-height:90px; @include transform(translate(-50%, -50%)); position: absolute; left:50%; top: 50%;}
.product_info .title{width:200px; display:inline-block; vertical-align: middle;}
.product_info .title h2 a{font-size:15px; text-align:center; width:200px; display:block; font-weight: 700; color: $black; text-transform: uppercase;}
.product_info .title h2 a:hover{color: $blue;}
.product_quantity input{ width: 36px; height: 36px; padding: 0; line-height: 36px; text-align: center; border: 1px solid #DDD; }
.up-del-holder{display: inline-block; vertical-align: middle; margin-left: 20px;}
.product_quantity a{ font-size: 15px; display: block; line-height: 25px; text-decoration: underline; color: $black;}
.product_quantity a span{padding-right: 10px;}
.product_quantity a:hover{text-decoration: underline; color: $blue;}
.product_quantity a.remove{ padding-bottom: 5px;}
.list_of_products_in_cart{clear: both; padding-top: 30px;}
.list_of_products_in_cart td.product_price{
  font-size: 21px;
  .price_decimal {
    font-size: 0.6em;
    position: relative;
    top: -0.5em;
  }
  .price_currency{font-size: 18px; text-transform: uppercase;}
}
.list_of_products_in_cart tr td:last-of-type{
  text-align: right; padding-right: 10px; color: $blue;
  font-size: 21px;
  .price_currency{font-size: 18px; text-transform: lowercase;}
}
.free-transport{font-size: 15px; position: relative; margin: -55px 0 0 0;}
.empty-cart-massage{font-size: 32px; padding: 20px 0; color: $blue;}

@media(max-width: 991px){
  .product_quantity a{ padding-left: 10px;}
  .free-transport{margin: -20px 0 0 0;}
}

@media(min-width: 768px) and (max-width: 991px){
  .product_quantity a{height: 25px; width: 25px;}
  .product_quantity a span{display: none;}
  .up-del-holder{margin-left: -3px;}
}

.total_price{width:100%; margin: 10px 0 25px 0; text-align:right; text-transform:uppercase; font-size: 21px; color: $red;}
.total_price p.total_text{ display:inline-block;}
.total_price p.total_number{display:inline-block;  padding-right:10px; padding-left: 15px; text-align:left;}
.total_price p.total_number .price_decimal{font-size: 14px; top: -6px; position: relative;}
.total_price p.total_number .price_currency{font-size: 16px; text-transform: lowercase;}

@media(max-width: 767px){
  /* Zebra striping */
  .list_of_products_in_cart tr:nth-of-type(even) { background: #eee; }
  .single-order-total{background-color: $white!important;}
  .list_of_products_in_cart th { background: $grey; color: white; font-weight: bold; }
  .list_of_products_in_cart td, .list_of_products_in_cart th { padding: 10px; border: 1px solid #ccc; text-align: left; }
  /* Force table to not be like tables anymore */
  .list_of_products_in_cart table, .list_of_products_in_cart thead, .list_of_products_in_cart tbody, .list_of_products_in_cart th, .list_of_products_in_cart td,
  .list_of_products_in_cart tr { display: block; clear: both; }
  .list_of_products_in_cart thead{height:0; border: none;}

  /* Hide table headers (but not display: none;, for accessibility) */
  .list_of_products_in_cart thead tr { display: none;}
  .list_of_products_in_cart tr { padding-left:0; padding-right:0; }
  /* Behave  like a "row" */
  .list_of_products_in_cart td { border: none; position: relative; padding-left: 30%; }
  /* Now like a table header */
  .list_of_products_in_cart td:before {
    position: absolute;
    top: 0px;
    left: 0;
    height: 100%;
    width: 30%;
    padding-left: 5px;
    font-family: Ubuntu, sans-serif;
    font-size: 15px;
    color: #775855;
    text-align: left;
  }

  .list_of_products_in_cart td.product_price:before{
    font-weight: 400;
    font-size: 15px;
  }

  /* Label the data */
  .list_of_products_in_cart td:nth-of-type(1):before { content: "PROIZVOD"; }
  .list_of_products_in_cart td:nth-of-type(2):before { content: "KOLIČINA"; }
  .list_of_products_in_cart td:nth-of-type(3):before { content: "CENA"; }
  .list_of_products_in_cart td:nth-of-type(4):before { content: "UKUPNO"; }

  .list_of_products_in_cart tbody tr td{text-align:left;}
  .list_of_products_in_cart td:before{padding-top: 10px}
  .list_of_products_in_cart .product_info {width:auto;}
  .list_of_products_in_cart .product_info .thumb{margin: 0}
  .list_of_products_in_cart .product_info .title{float:none; vertical-align:top; margin-left: 0; padding-top: 0px; width: 100%;}
  .list_of_products_in_cart .product_info .title h2{margin: 10px 0 0 0; }
  .list_of_products_in_cart .product_info .title h2 a{text-align:left; width: auto; font-size: 18px;}
  .list_of_products_in_cart tbody tr td.product_description .short_text{padding-left:30%; padding-right:10px;}
  .list_of_products_in_cart .product_quantity input, .list_of_products_in_cart .product_quantity a.reload, .list_of_products_in_cart .product_quantity a.remove{float:none; display:inline-block;}

  .list_of_products_in_cart td.product_quantity{padding: 10px 0 10px 30%;}
  .list_of_products_in_cart td.product_quantity input{margin: 10px 0 0 5px;}
  .list_of_products_in_cart td.product_quantity .up-del-holder{width: 130px;}
  .list_of_products_in_cart td.product_quantity .up-del-holder span{font-size: 12px;}
  .list_of_products_in_cart .product_quantity input, .list_of_products_in_cart .product_quantity a.reload, .list_of_products_in_cart .product_quantity a.remove{float:left; display:inline-block;}

  .list_of_products_in_cart tr td:last-of-type {text-align: left; padding-right: 0px;}
  .single-order-total:before{display: none;}

  .list_of_products_in_cart .product_price>span{padding-left: 5px;}
  .total_price p.total_text {font-size: 18px;}
}

#promo_form{position: relative;}
#promo_form input{border: 1px solid #ddd; font-size: 15px; line-height: 20px; padding: 9px 10px; text-transform: uppercase; width: 100%; height: 40px;}
.buttons_area .custom-button{
  width: 100%;
}
@media(max-width: 480px) {
  #promo_form button{width: 100%;}
  .full-width{width: 100%;}
}
.buttons_area{margin-bottom: 40px;}

/*LOGIN AND REGISTER*/
#login_register{clear: both; margin-bottom: 50px;}
#login_register>div{margin-top: 50px;}
#login_register>div>div{padding-left: 15px; padding-right: 15px;}
#login_register .segment_title{
  font-size: 15px;
  font-weight: 400;
  text-transform: uppercase ;
  padding: 10px 0;
  margin-bottom: 30px;
  position: relative;
  color: $blue;
  @media(max-width: 991px){
    font-size: 16px;
  }
}
#login_register  .segment_title.segment_right{
  color: $red;
}
#login_register #login_form h4{padding: 0 0 25px 0;}
#login_register #login_form input,
#login_register #login_form button{margin: 0 0 25px 0}
#forgot_pass_link{font-size: 13px; color: $black; line-height: 20px; margin-bottom: 20px; display: table; height: 40px; text-decoration: underline}
#forgot_pass_link:hover{color: $blue;}
#forgot_pass_link span{display: table-cell; vertical-align: middle}
#login_register #login_form .sign_up{position: relative; z-index: 1;}

#login_register .register_form p{font-size: 14px;}
#login_register .register_form .with_registration{padding-bottom: 20px; border-bottom: 1px solid #ddd;}
#login_register .register_form .withouth_registration{padding-top: 20px; padding-bottom: 20px;}


.registration_form{clear: both;  padding-bottom: 60px; padding-top: 20px;}
.registration_form .notification{
  font-weight: 300;
  .important {
    color: $red;
    font-weight: 400;
  }
  &.pos-helper {
    margin-top: 35px;
  }
}
.registration_form .subheading{font-size: 21px; line-height: 20px; line-height: 20px; padding: 10px 0; margin-top: 20px; text-transform: uppercase; font-weight: 700; color: $blue}
@media(max-width: 991px){
  .registration_form .right-side{margin-top: 40px;}
  #login_register .register_form .with_registration{padding-bottom: 20px;}
}


/*ADDRESS DELIVERY*/
#user_info_form{
  margin-bottom: 50px;
  clear: both;
  & .text-large{font-size: 21px; font-weight: 700}
  & .text-medium{font-size: 21px; font-weight: 400;}
}
.note_shiping{
  line-height: 167%;
  margin: 5px 0;
  padding: 15px 0;
  border: 1px solid #ccc;
  border-width: 1px 0;
  font-size: 15px;
  font-weight: 300;

  .important {
    color: $red;
    font-weight: 400;
  }
}
.user-personal-data p span{width: 120px; display: inline-block;}
.delivery_address_content{padding-bottom: 20px;}
.delivery_address_content .border-bottom{clear: both}
.delivery_address_content h3{margin-bottom: 20px; margin-top: 60px;}
.delivery_address_content .subheader{text-transform: uppercase;}
.delivery_address_content p{line-height: 120%; margin-bottom: 5px;}
.delivery_address_content p.name_last_name{margin: 10px 0; font-size: 18px; color: $blue;}
.delivery_address_content p>span{font-weight: 400;}
.delivery_address_content p.price-holder{text-align: right;
  @media(max-width: 767px){
    text-align: left;
    margin: 10px 0;
  }
}
.delivery_address_content p>span.price{font-size: 32px; font-weight: 400; display: inline-block; float: right; color: $blue;}
.delivery_address_content p>span.price .price_currency{text-transform: lowercase;}
.delivery_address_content p>span .price_decimal {font-size: 0.6em; position: relative; top: -0.5em;}

.delivery_address_content .separated-block{
  background-color: $blue;
  border: 1px solid $grey;
  border-width: 1px 0;
  padding: 20px 0;
  & p{
    margin: 0;
  }
}
.restitution{
  padding: 10px 0;

  .fake-checkbox label{
    text-align: left;
    line-height: 22px;
  }
}

.delivery_address>div{}
.delivery_address>div#new_address{margin-top: 30px;}
.payment_option{margin-top: 15px;}
.payment_option > p{display: inline-block}
.payment_option p{line-height: 38px;}
.payment_option .radio_buttons label{font-size: 16px; margin: 0; }
#payment_type>div{padding: 0 0 0 5px;}
#new_address_request label{text-align: left; cursor: pointer; font-weight: 400; color: $blue}
.radio-option-holder{display: inline-block;}
.radio-option-holder span{margin: 7px 0 0 0;}
#new_address h2{font-size: 21px; margin-bottom: 20px; text-transform: uppercase;}
#address-napomena{ font-size: 18px; margin-top: 25px;}

.payment_option{
  .radio-box label{
    display: block;
    color: $blue
  }
  .radio-box label:before{
    top:10px;
    background-color: transparent;
  }
  .radio-box label:after{
    top: 15px;
    background-color: $blue;
  }

}

.new_delivery_address{
  border-top: 1px solid #ccc;
  padding-top: 20px;
}
#new_address .text-large{
  padding-top: 15px;
}
#new_address .notification-text{
  font-weight: 300;
  .important {
    font-weight: 400;
    color: $red;
  }
  .mandatory {
    color: $red;
  }
}

#notification{
  color: $red;
  font-size: 22px;
  padding: 10px 0;
}


@media(max-width: 767px){
  .delivery_address_content .order-prices.total p{text-align: left;}
  .delivery_address_content .order-prices.total p>span.price{padding-left: 0;}
}
@media(max-width: 479px) {
  .radio-option-holder {
    display: block;
    width: 100%
  }
  #payment_type>div{
    width: 100%;
  }
}

/* DONE - PAYMENT INSTRUCTION PAGE */
.text-editor .payment_text{
  text-align: center;
  padding-top: 80px;
  padding-bottom: 80px;
  clear: both;

  .text {
    max-width: 620px;
    display: inline-block;
  }

  & h2{
    color: #258CC9;
    font-size: 36px;
    margin-bottom: 20px;
    font-weight: 400;
  }

  h3 {
    font-size: 22px;
    font-weight: 300;
    margin-bottom: 50px;
  }

  & p{
    font-size: 16px;
    font-weight: 300;

    strong {
      color: #258CC9;
      font-weight: 400;
    }

    a {
      color: #258CC9;
      text-decoration: underline;
    }
  }

  @media(max-width: 767px){
    padding-top: 40px;
    padding-bottom: 40px;

    & h2{
      font-size: 26px;
      margin-bottom: 15px;
    }

    h3 {
      font-size: 18px;
      margin-bottom: 30px;
    }

    & p{
      font-size: 14px;
    }
  }
  @media(max-width: 480px){
    padding-top: 30px;
    padding-bottom: 30px;

    & h2{
      font-size: 22px;
      margin-bottom: 10px;
    }

    h3 {
      font-size: 16px;
      margin-bottom: 20px;
    }

    & p{
      font-size: 12px;
    }
  }
}



.promo-code-holder {
  position: relative;
  padding: 0 130px 0 0;

  input {
    @include placeholder{
      color: $black;
      font-weight: 300;
    }
  }

  button {
    border: 0;
    padding: 10px 0;
    line-height: 20px;
    background-color: #f5f5f5;
    position: absolute;
    right: 0; top: 0;
    width: 130px;
    margin-top: 20px;
    font-size: 13px;
    font-weight: 700;
    text-transform: uppercase;
    @include transition-all(0.4s);

    &:hover {
      background-color: $blue;
      color: $white;
    }
  }
}