footer {
  color: $white;
  a {
    color: $white;
  }
}

.footer-top {
  background-color: #555;
  padding: 10px 0 10px 0;
  margin-top: 40px;

  .footer-logo-block {
    height: 190px;
    background-color: $white;
    position: relative;
    text-align: center;
  }

  .footer-logo {
    width: 150px; height: 70px;
    background: url('../images/foo-logo.png') no-repeat center;
    display: inline-block;
    margin-top: 20px;
  }

  a.read-more {
    position: absolute;
    bottom: 20px; right: 20px;
    background-color: #F5F5F5;
    color: $black;
    width: 110px;
    text-align: center;
    line-height: 30px;

    &:before {
      content: '';
      position: absolute;
      height: 30px; width: 10px;
      right: 100%; top: 0;
      background-color: $red;
      @include transition-all(0.2s);
    }

    &:hover {
      background-color: $red;
      color: $white;

      &:before {
        width: 0;
      }
    }
  }

  h4 {
    border-bottom: 1px solid $white;
    font-size: 14px;
    margin-top: 20px;
    padding-bottom: 5px;
    margin-bottom: 10px;
  }

  ul {
    li {
      padding: 5px 0;
      a {
        line-height: 25px;
        font-weight: 300;
        display: block;
      }
    }
  }

  .footer-about {
    p {
      margin: 0;
      line-height: 25px;
      padding: 5px 0 5px 20px;
      position: relative;
      &:nth-of-type(1):before{
        padding-top: 5px;
        content: "\f041";
        font-family: FontAwesome;
        position: absolute;
        left: 0; top: 0;
      }
      &:nth-of-type(2):before{
        padding-top: 5px;
        content: "\f095";
        font-family: FontAwesome;
        position: absolute;
        left: 0; top: 0;
      }
    }
  }
}

.footer-bottom {
  background-color: #333;
  text-align: center;
  padding-top: 20px;
  padding-bottom: 10px;

  .links {
    display: inline-block;
    a {
      display: inline-block;
      margin: 0 15px;
      font-weight: 700;
    }
  }

  .copyright {
    font-size: 12px;
    font-weight: 300;
    margin-bottom: 0;
    margin-top: 10px;
    a {
      color: $green;
    }
  }
}