/* FAQ */

.faq-page {
  margin-top: 20px;
  margin-bottom: 60px;

  .page-title {
    font-size: 24px;
    font-weight: 700;
    color: #258CC9;
    margin-bottom: 20px;
  }
}

#faq-acc{ clear: both;}
#faq-acc .ui-accordion-header-icon{display: none;}
#faq-acc .ui-widget-content {border: 0; background: transparent;  padding: 10px 0; border-bottom: 1px solid #ddd; width: 100%!important;}
#faq-acc .ui-state-hover, #faq-acc .ui-state-focus {background: transparent;}
#faq-acc h3{color: $black; font-family: 'Open Sans', sans-serif; margin: 0; font-size: 18px; font-weight: 600; line-height: 26px; padding: 12px 45px 12px 0;  display: block; position: relative; cursor: pointer; border: none;  border-bottom: 1px solid #ddd; background: transparent; border-radius: 0;}
#faq-acc h3:hover, #faq-acc h3:focus{color: $black; background: transparent;}
#faq-acc h3>span{position: absolute; right: 0;top: 10px; height: 30px; width: 30px; background: url("../images/faq-img.png") no-repeat bottom}
#faq-acc h3.ui-accordion-header-active{border-bottom: none; color: #258DC9;}
#faq-acc h3.ui-accordion-header-active>span{ background: url("../images/faq-img.png") no-repeat top}
#faq-acc>div{}
#faq-acc p{;color: #545454; font-family: 'Open Sans', sans-serif;}
#faq-acc a{color: inherit; text-decoration: underline;}
#faq-acc a:hover{color: #258DC9;}
.mar-t-50{
  clear: both;
  margin-top: 50px;
}
//.text_editor a{color: inherit; text-decoration: underline;}