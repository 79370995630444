.discount-container {
  height: 375px;
  margin-bottom: 90px;
  color: $white;
  position: relative;

  .green-block {
    display: inline-block;
    width: 520px; height: 130px;
    background-color: $green;
    position: absolute;
    bottom: -40px; left: 30px;
    padding: 20px;

    .banner-title {
      font-size: 32px;
      font-weight: 700;
    }

    a {
      position: absolute;
      bottom: 20px; left: 20px;
      font-size: 24px;
      text-decoration: underline;
    }
  }

  .red-block {
    position: absolute;
    right: 0; bottom: -20px;
    background-color: $red;

    p {
      margin: 0;
      line-height: 35px;
      padding: 20px 40px;
      font-size: 32px;
    }
  }


  @media (max-width: 600px) {
    height: 220px;
    margin-bottom: 70px;

    .green-block {
      width: 80%; height: 90px;
      bottom: -40px; left: 10%;
      padding: 10px;

      .banner-title {
        font-size: 24px;
      }

      a {
        bottom: 30px; left: 10px;
        font-size: 18px;
      }
    }

    .red-block {
      bottom: -20px;

      p {
        line-height: 25px;
        padding: 10px 20px;
        font-size: 24px;
      }
    }
  }
}