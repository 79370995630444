.banner{
  text-align: center;
  color: $white;
  background-color: #409AD0;
  position: relative;
  @include transition-all(0.4s);
  margin-bottom: 20px;

  &:before{
    content: "";
    position: absolute;
    left: 10px; right: 10px; top: 10px; bottom: 10px;
    border: 1px solid $white;
    border-radius: 6px;
    z-index: 1;
  }

  .banner-overlay{
    position: absolute;
    top: 0; right: 0; bottom: 0; left: 0;
    opacity: 0.5;
    background-color: rgba(0,0,0,0.2);
    @include transition-all(0.4s);
  }


  &:hover .banner-overlay{
    opacity: 1;
  }

  .text-holder{
    position: absolute;
    top: 50%; width: 100%;
    @include translate(0, -50%);
    font-size: 24px;
    font-weight: 700;
    & p{
      padding: 10px 15px;
      margin: 0;
    }

    @media(max-width: 991px) {
      font-size: 18px;
    }
  }
  > a{
    position: absolute;
    top: 0; right: 0; bottom: 0; left: 0;
    z-index: 1;
  }



  @media (max-width: 767px) {
    max-width: 400px;
    margin-top: 20px;
    margin-bottom: 0;
  }

}
