.contact-page.container{
  position: relative;
  z-index: 0;
  margin-bottom: 50px;
}

.contact-page_table{
  h1 {
    font-size: 32px;
    font-weight: 700;
    margin: 50px 0 20px 0;
    text-transform: uppercase;
    line-height: 35px;
  }

  & table{
    border: 0;
    font-size: 18px;
    margin-top: 20px;

    tbody {
      border-bottom: 1px solid $grey-light;
    }
  }

  & td:before{
    font-family: 'FontAwesome';
    content:"";
    line-height: 30px;
    position: absolute; left: 30px; top: 50%; margin-top: -15px;
    font-size: 30px;
    color: $blue;
  }

  & tr:nth-of-type(1) td:before{content: "\f095";}
  & tr:nth-of-type(2) td:before{content: "\f017";}
  & tr:nth-of-type(3) td:before{content: "\f276";}

  & td{
    border: 0;
    border-top: 1px solid $grey-light;
    line-height: 26px;
    padding: 32px 0 32px 80px;
    position: relative;
    font-size: 21px;

  }
}

.contact-page_form_mandatory{
  text-align: right;
  padding-right: 15px;
  position: relative;
  color: $red;
  line-height: 20px;
  margin: 65px 0 0 0;
  &:after{
    position: absolute;
    top: -4px;
    right: 2px;
    font-family: FontAwesome;
    content: "\f069";
    font-size: 12px;
    color: $red;
  }
}
.fake-placeholder {
  position: absolute;
  line-height: 20px;
  padding: 9px 15px;
  height: 40px;
  margin-top: 20px;
  z-index: 1;

  & span{
    position: relative;
    display: inline-block;
    padding-right: 15px;
  }

  &.mandatory span:after{
    position: absolute;
    top: -4px;
    right: 2px;
    font-family: FontAwesome;
    content: "\f069";
    font-size: 12px;
    color: $red;
  }
}


#contactForm{padding-bottom: 30px;}
#contactForm .bottom-part{
  position: relative;
  .captcha-holder {
    margin-top: 20px;
  }
}

#contact_form_error {
  p {
    text-align: right;
    font-size: 18px;
    color: $red;
    font-weight: 700;
    margin: 20px 0 0 0;
  }
}