.best-sale {

  h2 {
    font-size: 18px;
    color: $red;
    line-height: 40px;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 30px;

    span {
      display: inline-block;
      border-bottom: 2px solid $black
    }
  }

  @media (max-width: 480px) {
    margin-top: 30px;

    h2 {
      text-align: left;
      border-bottom: 2px solid $black;

      span {
        border-bottom: 0;
      }
    }

    .slick-arr-top {
      border-bottom: 0;
      border-top: 2px solid $black;
    }
  }
}
