// ***********************************
// BREADCRUMBS
// ***********************************
.breadcrubms .container{position: relative}
.breadcrubms ul{margin:0; padding:0; list-style:none; position: absolute; top: -40px; z-index: 6;}
.breadcrubms ul li{ float: none; display:inline-block;}
.breadcrubms ul li:after{
  content: "";
  position: absolute;
  right: 4px; top: 1px;
  height: 13px; width: 1px;
  background-color: #aaa;
}
.breadcrubms ul li:last-of-type:after{display: none;}
.breadcrubms ul li:first-child{padding-left:0;}
.breadcrubms ul li,
.breadcrubms ul li a{ text-decoration:none; cursor: pointer; position: relative; color: #aaa; font-size: 14px; }
.breadcrubms ul li a{ padding-right: 15px;}
.breadcrubms ul li:last-of-type a{padding-right: 0}
.breadcrubms ul li a:hover{ color: $blue; }
.breadcrubms ul li.current,
.breadcrubms ul li.current a{cursor: default; text-decoration: none; pointer-events: none; color: $blue; }

@media (max-width: 991px) {
  .breadcrubms ul{ padding: 20px 0 0 0; position: relative; top: 0; }
}
@media (max-width: 480px) {
  .breadcrubms ul{ padding: 10px 0 0 0; }
  .breadcrubms ul li a{ font-size: 12px; padding-right: 10px; }
}